import { RMAProviderOrNone } from "../RMAProvider.enum";
import { Reason } from "../rma_request_types";
import { AdjustmentSet } from "../adjustments/rma_adjustments";
import { LabelProvider } from "../TLabelProviders";
import {
  CarrierOption,
  TBudbeeOption,
  TCarrieredOption,
  isCarrierSelectableInAdminUI,
  isMethodBasedReturnOption,
} from "../CarrierOption";
import { PossibleCarrier } from "../response_types";
import {
  ConditionNode,
  ConditionSubject,
  deriveProductProperties,
  evaluateCondition,
} from "../properties_conditions";
import { LineItemDiscountProvider as LineItemDiscountProvider } from "../discounts/LineItemDiscountsProvider";

export enum DiscountStrategyProvider {
  SPREAD = "spread",
  MRMARVIS = "mrmarvis",
  BOGUS = "bogus",
}

export enum ShippingTrackingProvider {
  AFTERSHIP = "aftership",
  GENERIC = "generic",
}

export type TextFilter = {
  label: string;
  filterType: "return" | "tag" | "variant";
  filterValue: string;
};

export type Filter =
  | TextFilter
  | {
      label: string;
      filterType: "collection";
      filterValue: TextFilter[];
    };

type TReturnDestination = {
  postalCode: string;
  countryCode: string;
};

type TRefundShippingCosts = "none" | "original_only" | "all";

export type TAutoProcessTimes = {
  dayOfWeek: [boolean, boolean, boolean, boolean, boolean, boolean, boolean];
  startTime: string;
  endTime: string;
};

export type TCompanyInfo = {
  name: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
  phoneNumber: string;
  url: string;
  supportUrl: string;
  logo: string;
};

export const filterByCondition = <T extends CarrierOption>(
  options: T[],
  type: "exchange" | "return",
) => options.filter((o) => o.condition === "all" || o.condition === type);

export interface TCountrySettings {
  carriers: CarrierOption[];
  returnAddress: string | null;
  returnHandler: RMAProviderOrNone;
  refundShippingCosts: TRefundShippingCosts;
  processingTime?: number | null;
  shippingTime?: number | null;
  trackingReturnDestination: TReturnDestination | null;
  handedInAutoProcess: number | null;
  trackingProvider: ShippingTrackingProvider | null;
  provideCommercialInvoice?: boolean;
}

export type CountriesSettings = Partial<Record<string, TCountrySettings>>;

export type RMAFlagRule = {
  flag: string;
  condition: ConditionNode<"line_item" | "order" | "projected_order" | "rma">;
};

export type GlobalConfig = Partial<{
  allowPartialReturns: boolean;
  autoProcessTimeLimits: boolean;
  autoProcessTimeLimitValues: TAutoProcessTimes;
  hasBundleMetafields: boolean; // flag to determine whether we need to fetch additional metafields when a products webhook is coming in.
  warrantyWindowInDays: number | null;
  returnedAutoProcess: boolean;
  maximumAgeInDays: number;
  inventoryThreshold: number;
  reserveInventoryDays: number;
  inventoryThresholdHideBelow: boolean;
  adjustmentsV2: AdjustmentSet[];
  allowAdditionalPayment: boolean;
  returnableOptions: Filter[];
  reasons: Reason[];
  refundGiftCardsFirst: boolean;
  refundReturnedProductsOnly: boolean; // temporary: only refund products that actually came back -> for Agradi (they want this), but Stoov works with bundle products that change SKUs all the time
  countrySettings: CountriesSettings;
  companyInfo: TCompanyInfo;
  flagSettings?: {
    unregisteredRequest?: boolean;
    lessReturnedThanRegistered?: boolean;
    customerComment?: boolean;
    flagDamagedItem?: boolean;
  };
  restockOnRefund: boolean;
  returnItemsOnExchange: boolean;
  orderBubbleDepth?: number;
  discountStrategy: DiscountStrategyProvider | null;
  downloadLinkExpiryMinutes: number;
  emailNotifications: Record<string, string>;
  preserveTagsOnExchange: string[];
  pickupPointAttributes: string[];
  clientFlow:
    | "v1" // Pick exchange or return first, then fill out reason
    | "v2"; // Pick reason first, get unique options based on reason, then choose exchange or return
  allowMultiRma: boolean;
  /** If an order meets this condition, we disallow returning  */
  orderBlocking: OrderFilter[];
  lineItemsBlocking: LineItemFilter[];
  returnBlocking: OrderFilter[];
  rmaFlagging: RMAFlagRule[];
  allowHeadInjection: boolean;
  emailMerchantAboutErrors: boolean;
  /**
   * If the respect price setting is triggered, it will take the value at time of submissions, only in case of exchange
   * @example Customer buys pants for €100, and price increases to €110. Customer can refund worth of €100, and exchange worth of €110.
   */
  respectPriceIncreaseOnExchangeStrategy: RespectPriceIncreaseStrategy;
  lineItemDiscountsProvider: LineItemDiscountProvider;
  returnWindowExceptionTags: string[];
  useOverallInventory?: boolean;
  selectedInventoryLocations?: InventoryLocation[];
  appPreferences: {
    defaultLandingPage: "dashboard" | "requestsOverview";
  };
  trackingProviders?: Record<string, string>;
  trackingReturnDestinations?: Record<string, { countryCode: string; postalCode: string }>;
  vatNumber?: string;
  eoriNumber?: string;
  importVatNumber?: string;
  importEoriNumber?: string;
  incoterm?: string;
  disclaimer?: string;
  useWarehouseReturns?: boolean;
  defaultWarehouseCode?: string;
}>;

interface InventoryLocation {
  id: number;
  name: string;
  fulfillsOnlineOrders: boolean;
}

export const validPriceIncreaseStrategies = [
  "both-match",
  "none",
  "same-variant",
  "same-product",
  "always",
] as const;

const [bothMatch, ...simpleStrategies] = validPriceIncreaseStrategies;

export type RespectPriceIncreaseStrategy =
  | { type: (typeof simpleStrategies)[number] }
  | {
      type: typeof bothMatch;
      condition: ConditionNode<"product">;
    };

export type SubjectFilter<T extends ConditionSubject> = {
  userMessage: string;
  condition: ConditionNode<T>;
};

export type OrderFilter = SubjectFilter<"order">;
export type LineItemFilter = SubjectFilter<"line_item">;

export const getNewCountrySettings = (returnAddress: string | null): TCountrySettings => ({
  returnHandler: "none",
  handedInAutoProcess: null,
  carriers: [
    {
      condition: "all",
      labelProvider: "locallabel",
      label: "",
      returnInstructionsHtml: "",
      price: null,
    },
  ],
  processingTime: 15,
  refundShippingCosts: "none",
  returnAddress,
  trackingProvider: null,
  trackingReturnDestination: null,
});

const providersWithoutPrice: LabelProvider[] = ["activeants", "budbee", "transsmart", "sendcloud"];

export const isPriceSetByMerchant = (labelProvider: LabelProvider): boolean =>
  providersWithoutPrice.includes(labelProvider);

const providersWithProvidedLabelDescription: LabelProvider[] = ["monta"];

export const isLabelDescriptionSetByProvider = (labelProvider: LabelProvider): boolean =>
  providersWithProvidedLabelDescription.includes(labelProvider);

export const matchesOptionUnconditional = (
  optionA: PossibleCarrier,
  optionB: PossibleCarrier,
): boolean => {
  if (optionA.labelProvider !== optionB.labelProvider) {
    return false;
  }

  if (isMethodBasedReturnOption(optionA)) {
    return optionA.method === (optionB as TBudbeeOption).method;
  }

  if (isCarrierSelectableInAdminUI(optionA)) {
    return optionA.carrierId === (optionB as TCarrieredOption).carrierId;
  }

  return true;
};

export const matchesOptionConditional = (optionA: CarrierOption, optionB: CarrierOption): boolean =>
  matchesOptionUnconditional(optionA, optionB) && optionA.condition === optionB.condition;

export const shouldRespectPriceIncreaseOnExchange = (
  respectPriceIncreaseOnExchangeStrategy: GlobalConfig["respectPriceIncreaseOnExchangeStrategy"],
  originalItem: { productId: number; variantId: number; productTags: string[] },
  exchangeItem: { productId: number; variantId: number; productTags: string[] },
) => {
  if (
    respectPriceIncreaseOnExchangeStrategy === undefined ||
    respectPriceIncreaseOnExchangeStrategy.type === "none"
  ) {
    return false;
  }

  // If the strategy is "both-match", the tag needs to be the exact same on both.
  const sharedTags = originalItem.productTags.filter((tag) =>
    exchangeItem.productTags.includes(tag),
  );

  return (
    respectPriceIncreaseOnExchangeStrategy.type === "always" ||
    (respectPriceIncreaseOnExchangeStrategy.type === "same-product" &&
      originalItem.productId === exchangeItem.productId) ||
    (respectPriceIncreaseOnExchangeStrategy.type === "same-variant" &&
      originalItem.variantId === exchangeItem.variantId) ||
    (respectPriceIncreaseOnExchangeStrategy.type === "both-match" &&
      evaluateCondition(respectPriceIncreaseOnExchangeStrategy.condition, {
        product: deriveProductProperties({
          tags: sharedTags,
        }),
      }))
  );
};
